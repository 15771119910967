.jumbotron, .navbar {
        background-color: #feffd8 !important;
        border-bottom: 1px solid #ccc;
      }
      .jumbotron h1, .navbar-brand {
        font-family: 'Leckerli One';
        color: #bec !important;
        text-shadow: -2px -2px 2px #000;
      }
      .navbar-brand {
        font-size: 1.5rem;
        text-shadow: -1px -1px 1px #444;
      }
      .jumbotron p.lead {
        color: #777;
      }