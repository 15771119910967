.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*Expand collapse react*/


.react-expand-collapse__content {
  position: relative;
  overflow: hidden;
}

.react-expand-collapse__body {
  display: inline;
}

/* expand-collapse button */
.react-expand-collapse__button {
  color: #22a7f0;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #fff;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.react-expand-collapse__button:before {
  content: '';
  position: absolute;
  top: 0;
  left: -20px;
  width: 20px;
  height: 100%;
  background: linear-gradient(to right, transparent 0, #fff 100%);
}

/* expanded state */
.react-expand-collapse--expanded .react-expand-collapse__button {
  padding-left: 5px;
  position: relative;
  bottom: auto;
  right: auto;
}

.react-expand-collapse--expanded .react-expand-collapse__button:before {
  content: none;
}
